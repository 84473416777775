import Vue from 'vue';
import VueRouter from 'vue-router';

//解决重复路由
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: '/privacy',
    name: 'privacy',
    // component: () => import('../views/privacy.vue'),
    component: resolve => require(['../views/privacy.vue'], resolve)
  },
  {
    path: '/service',
    name: 'service',
    // component: () => import('../views/service.vue'),
    component: resolve => require(['../views/service.vue'], resolve)

  },
  {
    path: '/familyDescription',
    name: 'familyDescription',
    // component: () => import('../views/familyDescription.vue'),
    component: resolve => require(['../views/familyDescription.vue'], resolve)

  },
  {
    path: '/inviteCash',
    name: 'inviteCash',
    // component: () => import('../views/inviteCash.vue'),
    component: resolve => require(['../views/inviteCash.vue'], resolve)

  },
  {
    path: '/inviteCashDescription',
    name: 'inviteCashDescription',
    // component: () => import('../views/inviteCashDescription.vue'),
    component: resolve => require(['../views/inviteCashDescription.vue'], resolve)

  },
  {
    path: '/bindAccount',
    name: 'bindAccount',
    // component: () => import('../views/bindAccount.vue'),
    component: resolve => require(['../views/bindAccount.vue'], resolve)

  },
  {
    path: '/personalLevel',
    name: 'personalLevel',
    // component: () => import('../views/personalLevel.vue'),
    component: resolve => require(['../views/personalLevel.vue'], resolve)

  },
  {
    path: '/newPersonalLevel',
    name: 'newPersonalLevel',
    // component: () => import('../views/personalLevel.vue'),
    component: resolve => require(['../views/newPersonalLevel.vue'], resolve)

  },
  {
    path: '/familyLevel',
    name: 'familyLevel',
    // component: () => import('../views/familyLevel.vue'),
    component: resolve => require(['../views/familyLevel.vue'], resolve)

  },
  {
    path: '/patricianDescription',
    name: 'patricianDescription',
    // component: () => import('../views/patricianDescription.vue'),
    component: resolve => require(['../views/patricianDescription.vue'], resolve)

  },
  {
    path: '/familyIncome',
    name: 'familyIncome',
    // component: () => import('../views/familyIncome/familyIncome.vue'),
    component: resolve => require(['../views/familyIncome/familyIncome.vue'], resolve)

  },
  {
    path: '/transfer',
    name: 'transfer',
    // component: () => import('../views/transferMoney/transfer.vue'),
    component: resolve => require(['../views/transferMoney/transfer.vue'], resolve)

  },
  {
    path: '/transferMoney',
    name: 'transferMoney',
    // component: () => import('../views/transferMoney/index.vue'),
    component: resolve => require(['../views/transferMoney/index.vue'], resolve)

  },
  {
    path: '/addressBook',
    name: 'addressBook',
    // component: () => import('../views/transferMoney/addressBook.vue'),
    component: resolve => require(['../views/transferMoney/addressBook.vue'], resolve)

  },
  {
    path: '/record',
    name: 'record',
    // component: () => import('../views/transferMoney/record.vue'),
    component: resolve => require(['../views/transferMoney/record.vue'], resolve)

  },
  {
    path: '/eventRewards',
    name: 'eventRewards',
    component: resolve => require(['../views/eventRewards.vue'], resolve)

  },
  {
    path: '/listRules',
    name: 'listRules',
    component: resolve => require(['../views/listRules.vue'], resolve)

  },
  {
    path: '/diamondDescription',
    name: 'diamondDescription',
    component: resolve => require(['../views/diamondDescription.vue'], resolve)

  },
  {
    path: '/nobilityLevel',
    name: 'nobilityLevel',
    component: resolve => require(['../views/nobilityLevel.vue'], resolve)

  },
  // 图片转url页面
  {
    path: '/mediaPage',
    name: 'mediaPage',
    component: resolve => require(['../views/mediaPage/index.vue'], resolve),
  },
  // Layla Idol Help页面
  {
    path: '/idolHelp',
    name: 'idolHelp',
    component: resolve => require(['../views/idolHelp/index.vue'], resolve),

  },
  // 狂欢派对
  {
    path: '/raveParty',
    name: 'raveParty',
    component: resolve => require(['../views/raveParty/index.vue'], resolve),

  },
  // 主播活动
  {
    path: '/anchorActive',
    name: 'anchorActive',
    component: resolve => require(['../views/anchorActive/index.vue'], resolve),

  },
  // lucky Poker活动
  {
    path: '/luckyPoker',
    name: 'luckyPoker',
    component: resolve => require(['../views/luckyPoker/index.vue'], resolve),

  },
  // zainCash
  {
    path: '/namePhone',
    name: 'namePhone',
    component: resolve => require(['../views/namePhone/index.vue'], resolve),

  },
  // 仲夏梦之夜
  {
    path: '/dreamNight',
    name: 'dreamNight',
    component: resolve => require(['../views/dreamNight/index.vue'], resolve),

  },
  // pk活动
  {
    path: '/pkTournamentPro',
    name: 'pkTournamentPro',
    component: resolve => require(['../views/pkTournamentPro/index.vue'], resolve),

  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      //切换到新路由时，页面滚动到顶部
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

<script>
  function complete(funName,datas) {
    if (funName == 'getShare') {
      let data = {
        downloadUrl: 'https://h5game.koudailive.com/webpage/#/download?langType='+datas,
      }
      try {
        nativeJs[funName](JSON.stringify(data));
      } catch (e) {
        window["webkit"].messageHandlers[funName].postMessage(JSON.stringify(data));
      }
    }
    else {
      try {
        nativeJs[funName]();
      } catch (e) {
        if(window["webkit"]){
          window["webkit"].messageHandlers[funName].postMessage(JSON.stringify(""));
        }
      }
    }

  }


  function loadErudaJs() {
    let script = document.createElement("script");
    script.src = "https://cdn.bootcss.com/eruda/1.3.0/eruda.min.js";
    script.type = "text/javascript";
    document.getElementsByTagName("head")[0].appendChild(script);
    script.onload = function () {
      eruda.init();
    };
  }

  function formatDate(time) {
      function add0(m) { return m < 10 ? '0' + m : m }
      var time = new Date(time);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);

    }

    
  export default {
    complete,
    loadErudaJs,
    formatDate,
  };
</script>
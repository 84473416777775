// 动态计算class 财富等级
export function countClass(levele){
    let className = '';
    if(levele > 0 && levele <= 15){
      className = 'wealth1_15';
    }else if(levele > 15 && levele <= 30){
      className = 'wealth16_30';
    }else if(levele > 30 && levele <= 45){
      className = 'wealth31_45';
    }else if(levele > 45 && levele <= 60){
      className = 'wealth46_60';
    }else if(levele > 60 && levele <= 75){
      className = 'wealth61_75';
    }else if(levele > 75 && levele <= 99){
      className = 'wealth76_99';
    }else if(levele > 99 && levele <= 109){
      className = 'wealth100_109';
    }else if(levele > 109 && levele <= 119){
      className = 'wealth110_119';
    }else if(levele > 119 && levele <= 129){
      className = 'wealth120_129';
    }else if(levele > 129 && levele <= 139){
      className = 'wealth130_139';
    }else if(levele > 139 && levele <= 149){
      className = 'wealth140_149';
    }else if(levele > 149 && levele <= 159){
      className = 'wealth150_159';
    }else if(levele > 159 && levele <= 169){
      className = 'wealth160_169';
    }else if(levele > 169 && levele <= 179){
      className = 'wealth170_179';
    }else if(levele > 179 && levele <= 189){
      className = 'wealth180_189';
    }else if(levele > 189 && levele <= 200){
      className = 'wealth190_200';
    }else if(levele >= 201 && levele <= 210){
      className = 'wealth201_210';
    }else if(levele >= 211 && levele <= 220){
      className = 'wealth211_220';
    }else if(levele >=221 && levele <= 230){
      className = 'wealth221_230';
    }else if(levele >= 231 && levele <= 240){
      className = 'wealth231_240';
    }else if(levele >= 241 && levele <= 250){
      className = 'wealth241_250';
    }else if(levele >= 251 && levele <= 260){
      className = 'wealth251_260';
    }else if(levele >= 261 && levele <= 270){
      className = 'wealth261_270';
    }else if(levele >= 271 && levele <= 280){
      className = 'wealth271_280';
    }else if(levele >= 281 && levele <= 290){
      className = 'wealth281_290';
    }else if(levele >= 291 && levele <= 300){
      className = 'wealth291_300';
    }
    // this.wealthArr.push(className);
    return className;
  }
// 动态计算魅力等级class
  export function countCharmClass(levele){
    let className = '';
    let counLevele = parseInt(levele/15) + 1;
    
    if(counLevele < 6){
      className = 'charm' + counLevele;
    }else{
      className = 'charm6';
    }
    // this.wealthArr.push(className);
    console.log(className,'00000')
    return className;
  }
 
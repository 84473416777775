import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueI18n from 'vue-i18n';
// import i18n from './lang' // internationalization
// import './providers/polyfill.min';
import Vant from 'vant';
import 'vant/lib/index.css';
import { server,axiosApi } from './providers/http-service';
import { countClass,countCharmClass } from './providers/global';
import bridge from './components/brideg.vue';
import enLocale from './lang/en';
import zhLocale from './lang/zh';
import arLocale from './lang/ar';
import tcLocale from './lang/tc';
import trLocale from './lang/tr';
import ptLocale from './lang/pt';
import esLocale from './lang/es';
import './assets/text/text.css';
// import 'es6-promise/auto';
const messages = {
  en: {
    ...enLocale,
  },
  ar: {
    ...arLocale,
  },
  zh: {
    ...zhLocale,
  },
  tc: {
    ...tcLocale,
  },
  tr: {
    ...trLocale,
  },
  pt: {
    ...ptLocale,
  },
  es: {
    ...esLocale,
  },
};
Vue.prototype.$completes = bridge;
// Vue.prototype.$serviceIp = 'http://prod-node-ops-01/';//正式服
// Vue.prototype.$serviceIp = 'http://dev.api.koudailive.com/';//测试服
Vue.prototype.$serviceIp = process.env.VUE_APP_BASE_URL;
Vue.prototype.$serviceIpJava = process.env.VUE_APP_BASE_URL_JAVA;
// serviceIp: 'https://api.pocketliveapp.com/',
// serviceIp: 'http://dev.api.koudailive.com/',
// import $ from 'jquery'
Vue.prototype.countClass = countClass;
Vue.prototype.countCharmClass = countCharmClass;
Vue.use(Vant);
Vue.use(VueI18n);

Vue.prototype.$server = server;
Vue.prototype.$axios = axiosApi;

Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: 'en', // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages,
});
 
//弹出框禁止滑动
 
Vue.prototype.stopScroll = function () {
  var mo = function (e) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)// 禁止页面滑动
 }
 //弹出框可以滑动
 Vue.prototype.canScroll = function () {
  var mo = function (e) {
   e.preventDefault()
  }
  document.body.style.overflow = ''// 出现滚动条
  document.removeEventListener('touchmove', mo, false)
 }


document.querySelector('html').setAttribute('lang', i18n.locale);
(function() {
  let doc = document;
  let win = window;
  var docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function() {
      var clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      if (clientWidth >= 640) {
        docEl.style.fontSize = '100px';
      } else {
        docEl.style.fontSize = 100 * (clientWidth / 640) + 'px';
      }
    };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})();

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

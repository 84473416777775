export default{
    "pkTouramentPro": "PK Tourament Pro",
    "pkTop1": "Top1",
    "pkTop2": "Top2",
    "pkTop3": "Top3",
    "pk1000Usd": "1000 USD",
    "pk500Usd": "500 USD",
    "pk300Usd": "300 USD",
    "pkRewards": "Rewards for each Qualifying Round",
    "pkRewards2": "Rewards for each Promotion Round",
    "pk50kCoins": "50k Coins Target",
    "pk100kCoins": "100k Coins Target",
    "pkNote": "Note: for qualifying round and promotion round rewards, if the diamonds spent in the specific PK is less than the reward amount. The reward will not be sent but the PK result is still counted",
    "pk200Usd": "200 USD",
    "pk30Day": "*30 Days",
    "pk15Day": "*15 Days",
    "pk7Day": "*7 Days",
    "pkNote2": "Note：Support in all PK Rounds is calculated. Lucky gifts are counted 10% Treasure Boxes are counted based on the gift won amount. ",
    "pkTourament": "Tourament",
    "pkToday": "Today Schedule",
    "pkSupporter": "Supporter",
    "pkQualifying": "Qualifying Round",
    "pkPromotion": "Promotion Round",
    "pkSummit": "Summit Series",
    "pk16Finals": "1/16-Finals",
    "pk8Finals": "1/8-Finals",
    "pkQuater": "Quater-Finals",
    "pkSemi": "Semi-Finals",
    "pkFinal": "Final",
    "pk3rd": "3rd Place",
    "dreamMidsummer": "Midsummer Night's Dream",
    "dreamSend": "Send exclusive gifts to seal your midsummer love",
    "dreamRank": "Sending the following event gifts during the event. The higher the ranking, the richer the prizes. Come and participate to get generous rewards!",
    "dreamPreview": "Rewards Preview",
    "dreamSupporter": "Supporter",
    "dreamHost": "Host",
    "dreamTop1": "Top1",
    "dreamTop2": "Top2",
    "dreamTop3": "Top3",
    "dreamTop4_10": "Top4-10",
    "dreamRankings": "Rankings",
    "dreamRose": "Rose Throne",
    "dreamRoseBasket": "Rose Basket",
    "dreamButterfly": "Butterfly Wonderland",
    "dreamLovers": "Everlasting Love",
    "dreamFlower": "How Much Love",
    "dream15d": "*15d",
    "dreamCentury": "Glorious Century",
    "dream7": "*7",
    "dream7d": "*7d",
    "dreamHeart": "Heart Flying",
    "dreamSummerLove": "Summer Love",
    "dreamFancy": "Fancy Unicorn",
    "dreamRules": "Event Rules",
    "dreamTime": "Event time",
    "dreamPlay": "How to play",
    "dreamUsersTop": "During the event, via sending specific gifts, users are ranked based on the number of diamonds given and coins received. Users with top rankings can receive rewards.",
    "dreamRewards": "Rewards for Rose Basket and Butterfly Wonderland Ranking",
    "dreamFrame": "Rose Throne Frame",
    "dreamCenturyGift": "Glorious Century Gift",
    "dreamEntrance": "Heart Flying Entrance",
    "dream5": "*5",
    "dream5d": "*5d",
    "dream3": "*3",
    "dream3d": "*3d",
    "dreamDiamonds": "Diamonds",
    "dream25000": "*25000",
    "dreamVerification": "Summer Love Verification",
    "dreamUnicorn": "Fancy Unicorn",
    "dreamEverlasting": "Rewards for Everlasting Love and How Much Love Rank",
    "dreamCoins": "Coins",
    "dream100000": "*100000",
    "dream50000": "*50000",
    "dream5000": "*5000",
    "dreamHostBottom": "Coins counted in the target",
    "zainCashTitle": "Zain Cash",
    "zainTotalPay": "Pago Total",
    "zainIqd": "IQD",
    "zainName": "Nombre",
    "zainNameTips": "Ingrese su nombre como aparece en su ID nacional",
    "zainPhoneNumber": "Número de Teléfono",
    "zainPhoneNumberTips": "Ingrese su número de teléfono",
    "zainSubmit": "Pagar",
    "zainNumberErrorTips": "Por favor, ingrese un número o el signo +",
    "zainNameNotNull": "El nombre no puede estar vacío",
    "luckyEventStartsIn": "Evento comienza en",
    "luckyEventEndsIn": "",
    "luckyEventInstruction": "Instrucciones del evento",
    "luckySendingFollowing": "Enviar el siguiente regalo puede obtener una ovejita",
    "luckyRoom": "Sala",
    "luckyRoomTips": "",
    "luckyGifter": "Donador",
    "luckyAnchors": "",
    "luckyGoldenBoot": "",
    "luckyFootball": "",
    "luckyDefensiveMaster": "",
    "luckyTheWorldCup": "",
    "luckyNoOne": "Nadie en la lista todavía~",
    "luckyRules": "Reglas",
    "luckyHowTo": "¿Cómo entrar en el ranking?",
    "luckyHowToGetI": "Cómo obtener",
    "luckyTheGifts": "Los regalos dados en la actividad se clasifican según el número acumulado de diamantes, los regalos recibidos del evento se clasifican según el número acumulado de monedas de oro.",
    "luckyRewards": "Recompensas",
    "luckyRoomReward": "Recompensa de la sala",
    "luckyGifterRewards": "Recompensas del donador",
    "luckyBlessingCard": "Tarjeta de bendición",
    "luckyTop_1": "Créditos 100000",
    "luckyTop_2": "Marco de avatar x<span>10<span>días",
    "luckyTop_3": "Entrada x10días",
    "luckyTop_1_1": "",
    "luckyTop_2_1": "",
    "luckyTop_3_1": "",
    "luckyTop_4": "Bandera Exclusiva",
    "luckyEventEnd": "Fin del Evento",
    "anchorActive": {
        "activeList": "Lista de Activos",
        "activeListRewards": "Recompensas de la Lista de Activos:",
        "back": "Volver",
        "banner10": "Banner personalizado (10 días)",
        "capture": "Ser Estrella Nueva; Ganar recompensas",
        "charming": "Recompensas de la Lista de Encanto:",
        "charmingList": "Lista de Encanto",
        "days": "Días",
        "entrance10": "Entrada Exclusiva(10 días)",
        "entrance5": "Entrada Exclusiva(5 días)",
        "entrance7": "Entrada Exclusiva(7 días)",
        "frame10": "Marco de avatar exclusivo (10 días)",
        "frame5": "Marco de avatar exclusivo (5 días)",
        "frame7": "Marco de avatar exclusivo (7 días)",
        "hours": "Horas",
        "laseWeek": "Semana Pasada",
        "laseWeekRank": "Lista de Semana Pasada",
        "listOne": "Lista: Lista de Activos",
        "listOneMsg1": "Los ingresos de puntos obtenidos por host en 1v1 = valor de activo correspondiente(Incluyendo respuestas de host, llamadas de voz y video, y todos los puntos excepto por recibir regalos)",
        "listOneMsg2": "Duración/minuto del micrófono de la sala de voz=10 puntos",
        "listTwo": "Lista 2: Lista de Encanto",
        "listTwoMsg1": "Cantidad de diamantes de regalo en 1v1 = Valor de encanto correspondiente",
        "listTwoMsg2": "La cantidad de diamantes recibidos en la sala de voz = el doble del valor de ecanto.",
        "listTwoMsg3": "Hosts solo reciben el 10% del valor de encanto de regalos oportunados",
        "minutes": "Minutos",
        "noRanking": "No hay datos de clasificación disponibles.",
        "points100000": "10k puntos",
        "points30k": "30k puntos",
        "points50000": "5k puntos",
        "points50k": "50k puntos",
        "rank": "Ranking",
        "rewards": "Recompensas",
        "rules": "Normas",
        "seconds": "Segundos",
        "top1": "TOP1",
        "top2": "TOP2",
        "top3": "TOP3"
    },
    "bindAccount": {
        "Bank_Transfer": "Transferencia Bancaria",
        "Bind_Account": "Vincular Cuenta",
        "limit": {
            "account_name_tips": "Ingresa el Nombre Completo en Inglés",
            "bank_card_number": "Ingresa el Número de Cuenta Bancaria, ej.: 10000000000",
            "bankCode": "Ingresa el Código Swift del Banco, ej.: CMBCCNBS",
            "bankName": "Ingresa el Nombre del Banco en Inglés, ej.: State Bank of India",
            "City": "Ingresa tu Ciudad en Inglés",
            "country_code": "Ingresa el Código del País en Mayúsculas, ej.: IN",
            "name_tips": "Ingresa el Primer Nombre en Inglés",
            "payoneer_email": "Encuentra el Correo Electrónico Vinculado a tu Cuenta de Payoneer",
            "payoneerID": "Encuentra tu ID en tu Cuenta de Payoneer, ej.: 47289645466",
            "paypal_email": "Ingresa el Correo Electrónico Vinculado a su Cuenta de PayPal",
            "State_or_Province": "Ingresa el Estado o Provincia en Inglés",
            "Street": "Ingresa la Calle en Inglés",
            "StrePostal_Codeet": "Ingresa el Código Postal del Domicilio"
        },
        "Payment_Method": "Método de Pago",
        "placeholder": {
            "bank_account_name": "Nombre de la Cuenta Bancaria del Beneficiario",
            "bank_card_number": "Número de la Cuenta Bancaria del Beneficiario",
            "bankCode": "Código del Banco del Beneficiario",
            "bankName": "Nombre del Banco del Beneficiario en Inglés",
            "Beneficiary_country_code": "Código del País del Beneficiario",
            "City": "Dirección del Beneficiario - Ciudad",
            "country": "Código del País de la Tarjeta Bancaria",
            "Payee_First_name": "Primer Nombre del Beneficiario",
            "Payee_Last_Name": "Apellido del Beneficiario",
            "payoneer_email": "Ingresa tu correo electrónico",
            "payoneerID": "Ingresa tu ID de Payoneer",
            "paypal_email": "Ingresa el correo de tu cuenta",
            "Postal_Code": "Dirección del Destinatario - Código Postal",
            "State_or_Province": "Dirección del Destinatario - Estado o Provincia",
            "Street": "Calle"
        },
        "popup": {
            "Bound_account": "Vincular Cuenta:",
            "cancel": "Cancelar",
            "complete": "Listo",
            "confirm": "Confirmar",
            "country": "País de la Tarjeta Bancaria",
            "payment_method": "Método de Pago",
            "payoneer_email": "Correo Electrónico de la Cuenta Payoneer"
        },
        "title": {
            "bank_account_name": "Nombre de la Cuenta Bancaria del Beneficiario",
            "bank_card_number": "Cuenta Bancaria del Beneficiario",
            "bankCode": "Código del País de la Tarjeta Bancaria",
            "bankName": "Nombre del Banco del Beneficiario",
            "country": "País de la Tarjeta Bancaria",
            "Payee_Address": "Dirección del Beneficiario",
            "Payee_First_name": "Primer Nombre del Beneficiario",
            "Payee_Last_Name": "Apellido del Beneficiario",
            "payoneer_email": "Correo electrónico de la cuenta Payoneer",
            "payoneerID": "Payoneer ID no puede estar vacío",
            "paypal_email": "Correo Electrónico de la Cuenta PayPal"
        },
        "toast": {
            "bank_account_name": "El Nombre de la Cuenta Bancaria del Destinatario no puede estar vacío",
            "bank_card_number": "El Número de la Cuenta Bancaria del Destinatario no puede estar vacío",
            "bankCode": "El Código del Banco del Destinatario no puede estar vacío",
            "bankName": "El Nombre del Banco del Destinatario no puede estar vacío",
            "Beneficiary_country_code": "El País del Destinatario no puede estar vacío",
            "City": "La Ciudad del Destinatario no puede estar vacía",
            "country": "El País de la Tarjeta Bancaria no puede estar vacío",
            "emailyz": "Por favor, ingresa una dirección de correo electrónico válida y legal",
            "Payee_First_name": "El Primer Nombre del Destinatario no puede estar vacío",
            "Payee_Last_Name": "El Apellido del Destinatario no puede estar vacío",
            "payoneer_account": "Por favor, verifica nuevamente para evitar cualquier pérdida financiera",
            "payoneer_email": "El Correo Electrónico de Payoneer no puede estar vacío",
            "payoneerID": "La Identificación de Payoneer no puede estar vacía",
            "paypal_email": "El Correo Electrónico de Payoneer no puede estar vacío",
            "State_or_Province": "El Estado o Provincia del Destinatario no puede estar vacío",
            "Street": "La Dirección del Destinatario no puede estar vacía"
        }
    },
    "diamondDescription": {
        "title": "Reglas de VIP y SVIP",
        "txt1": "1. VIP y SVIP pueden activarse simultáneamente.",
        "txt2": "2. Los usuarios VIP pueden recibir cupones de mensajes gratuitos diariamente.",
        "txt3": "3. Los usuarios SVIP pueden recibir diamantes gratuitos diariamente.",
        "txt4": "4. Los usuarios SVIP pueden completar tareas de reembolso de diamantes diariamente.",
        "txt5": "5. Las tareas de reembolso de diamantes SVIP deben reclamarse el mismo día en que se actualizan. Los cupones diarios de mensajes VIP también deben reclamarse el mismo día; no se acumulan si no se reclaman y caducan.",
        "txt6": "6. El gasto en el juego no se contabilizará como parte de la cantidad de diamantes consumidos."
    },
    "eventRewards": {
        "player": "Número de jugadores activos diarios",
        "proportion": "Porcentaje a recibir si la sala gana",
        "title": "Retorno de Actividad",
        "txt1": "1. Calcular datos del día anterior a las 00:00 (UTC+8) diariamente.",
        "txt2": "2. Distribuir recompensas basadas en el número de jugadores activos y el consumo real de diamantes en la sala (diamantes apostados por los usuarios - diamantes ganados por los usuarios).",
        "txt3": "3. Calcular datos semanales cada lunes a las 00:00 (UTC+8), con recompensas a emitirse en un plazo de 3 días.",
        "txt4": "4. Proporción de distribución de recompensas:",
        "txt5": "5. Definición de jugador activo: un jugador que ha apostado más de 1.000 diamantes en total en ese día."
    },
    "failed": "Operación fallida！",
    "idolHelp": {
        "content1": "Layla Idol es una designación especial otorgada por la aplicación Layla a las chicas populares. Convertirte en Layla Idol viene con varios privilegios, incluyendo una insignia especial, más exposición y más mensajes de coincidencia.",
        "content2_1": "1. Primero, debes pasar la verificación de persona real. En segundo lugar, tu puntuación de chat debe alcanzar 80 puntos.",
        "content2_2": "2. Cumple con los requisitos de actividad del día, y la puntuación de chat se calculará a las 4 AM (UTC+8) el día siguiente basado en los datos de los últimos 7 días.",
        "content2_3": "3. Si no te cumples los requisitos de actividad del día, la puntuación de chat se reiniciará a 0 al día siguiente. Volverte activa nuevamente reiniciará la acumulación y cálculo de la puntuación de chat.",
        "content2_4": "4. Los requisitos de actividad diaria son los siguientes:",
        "content2_4_1": "a.Más de",
        "content2_4_2": "b.Más de",
        "content2_4_3": "c.Haber iniciado sesión en la aplicación el día anterior.",
        "content3_1": "La puntuación de chat se calcula basada en tu popularidad en escenarios de chat privado. Esto incluye:",
        "content3_2": "1. Exposición. Cuántas personas te han visto en la página de inicio, en los m,omentos o en tu página de perfil.",
        "content3_3": "2. Duración en línea. Cuánto tiempo has permanecido en la aplicación.",
        "content3_4": "3. Número de intimidad activa. Cuántos usuarios con una intimidad mayor a 3 te han enviado mensajes en el día.",
        "content3_5": "4. Tasa de respuesta de mensajes. El porcentaje de tus mensajes que han recibido respuesta.",
        "content3_6": "5. Número de personas con las que has iniciado chats. Cuántos usuarios has saludado o enviado mensajes.",
        "content3_7": "6. Tasa de respuesta efectiva. La tasa a la que respondes a los mensajes de otros dentro de un tiempo válido.",
        "content3_8": "7. Consumo promedio de diamantes en chat. Cuántos diamantes han gastado las personas chateando contigo, incluyendo chat y regalos 1v1.",
        "content3_9": "8. Número de personas que han gastado diamantes en chats. Cuántas personas han gastado diamantes mientras chateaban contigo, incluyendo chat y regalos 1v1.",
        "content4_1": "Puedes ver los valores estadísticos de tus diversos datos y compararlos con los de Layla Idols en la página de detalles de la puntuación de chat. ",
        "content4_2": "Hacer clic en los consejos para los ítems correspondientes te guiará sobre cómo mejorar esos valores.",
        "header1": "¿Qué es Layla Idol?",
        "header2": "Cómo Convertirte en Layla Idol",
        "header3": "¿Qué es una Puntuación de Chat?",
        "header4": "Cómo Mejorar Tu Puntuación de Chat",
        "minute": "minutos de tiempo en línea durante los últimos 7 días.",
        "people": "personas chatean activamente contigo durante los últimos 7 días.",
        "title": "Ayuda de Layla Idol"
    },
    "inviteCash": {
        "COPY": "Copiar",
        "Copy_successfully": "Copiado correctamente",
        "title": "Invitar para ganar dinero"
    },
    "Level": {
        "avatarFrame": "Marco de avatar",
        "badgeReward": "Medallas de premios",
        "charmLevel": "Clasificación del carisma",
        "currentExp": "Valor de experiencia actual",
        "entranceEffect": "Efecto de entrada",
        "familyLevel": "Nivel Familiar",
        "familyLeveldata": [
            "Niveles",
            "Cantidad de contribución requerida",
            "Número de Miembros",
            "Identidad"
        ],
        "gradePrivileges": "Privilegios de Nivel",
        "identity": [
            "Vice Precidente",
            "Anciano"
        ],
        "personLevel": "Nivel personal",
        "stillNeed": "requerido",
        "title": "Niveles",
        "upgrade": "¿Cómo subir de nivel?",
        "upgradebox": {
            "charm": [
                {
                    "title": "Recibir regalos",
                    "value": " 1 moneda de oro = 1 valor de carisma"
                }
            ],
            "family": [
                {
                    "title": "Tareas Familiares",
                    "value": "Recuperar contribución familiar"
                }
            ],
            "person": [
                {
                    "title": "Dar regalos",
                    "value": "1 Diamante = 1 Experiencia Personal"
                },
                {
                    "title": "Completar Tareas",
                    "value": "Recuperar experiencia personal"
                }
            ],
            "wealth": [
                {
                    "title": "Dar regalos",
                    "value": "1 Diamante = 1 Riqueza de Experiencia"
                },
                {
                    "title": "Completar Tareas",
                    "value": "Recuperar experiencia de riqueza"
                }
            ]
        },
        "wealthLevel": "Nivel de Riqueza"
    },
    "nation": {
        "ae": "Emiratos Árabes Unidos",
        "ao": "Angola",
        "ar": "Argentina",
        "at": "Austria",
        "au": "Australia",
        "bd": "Bangladesh",
        "bh": "Bahrein",
        "br": "Brasil",
        "ca": "Canadá",
        "cl": "Chile",
        "cn": "China Continental",
        "de": "Alemania",
        "dz": "Argelia",
        "eg": "Egipto",
        "es": "España",
        "fr": "Francia",
        "gb": "Inglaterra",
        "hk": "Hong Kong, China",
        "il": "Israel",
        "iq": "Iraq",
        "ir": "Irán",
        "it": "Italia",
        "jo": "Jordania",
        "jp": "Japón",
        "kw": "Kuwait",
        "lb": "Líbano",
        "lv": "Letonia",
        "ly": "Libia",
        "ma": "Holanda",
        "mx": "México",
        "my": "Malasia",
        "nl": "Países Baixos",
        "om": "Omán",
        "ph": "Filipinas",
        "pk": "Pakistán",
        "ps": "Palestina",
        "pt": "Portugal",
        "qa": "Qatar",
        "ru": "Rusia",
        "sa": "Arabia Saudí",
        "sd": "Sudán",
        "sy": "Siria",
        "tn": "Túnez",
        "tr": "Turquía",
        "tw": "Taiwán, China",
        "us": "Estados Unidos",
        "ye": "Yemen"
    },
    "nobilityLevel": {
        "diamond": "Consumo de Diamantes",
        "head": "Nivel de Nobleza",
        "title": "Reglas de Nivel de Nobleza",
        "txt1": "1. A partir del primer día de cada mes natural a las 00:00 (UTC+8), se realiza un seguimiento del consumo de diamantes por parte del usuario (excluyendo el consumo de diamantes en el juego). Los usuarios reciben privilegios de Nobleza en función de su consumo de diamantes.",
        "txt2": "2. El consumo regular de diamantes es de 1:1, mientras que los regalos afortunados son de 10:1 (redondeados hacia abajo).",
        "txt3": "3. Las clasificaciones de Nobleza cambiarán en función del consumo de diamantes del usuario en el mes actual, y la clasificación de Nobleza más alta alcanzada en el mes actual continuará para el próximo mes. El consumo de diamantes en el mes siguiente afectará de manera similar a los privilegios nobles en el mes siguiente. Se pueden considerar varios escenarios, como se muestra a continuación:",
        "txt4": "I. Después de alcanzar la clasificación de Nobleza correspondiente, la clasificación de Nobleza y los privilegios se mantendrán hasta el final del próximo mes. Por ejemplo, si un usuario llega al nivel 4 el 17 de octubre, los privilegios del nivel 4 continuarán hasta el 30 de noviembre.",
        "txt5": "II. Dentro del período del mes natural actual, si un usuario alcanza una clasificación de Nobleza más alta después de obtener una más baja, recibirán directamente la clasificación noble de mayor nivel, y la clasificación correspondiente se mantendrá hasta el final del próximo mes. Por ejemplo, si un usuario llega al nivel 2 el 17 de octubre y luego alcanza el nivel 4 el 19 de octubre, recibirán directamente los privilegios del nivel 4, que durarán hasta el 30 de noviembre.",
        "txt6": "III. Después de que finalice el mes natural actual, el nivel VIP puede mantener lo alcanzado en el mes anterior, pero la acumulación de puntos VIP comenzará desde cero en el mes siguiente. Por ejemplo, si un usuario llega al nivel 4 en octubre, mantiene el nivel 4 en noviembre y solo llega al nivel 2 al final de diciembre, solo disfrutarán de los privilegios del nivel 2 a partir del 1 de diciembre."
    },
    "patricianDescription": [
        {
            "title": "1. Qué es la Nobleza",
            "vlaue": [
                "La Nobleza es un símbolo del prestigio de los usuarios en la plataforma y actualmente se divide en seis niveles.",
                "Los diferentes niveles de Nobleza tienen sus correspondientes privilegios y pueden activarse pagando una cuota mensual para obtener y disfrutar de los privilegios."
            ]
        },
        {
            "title": "2. Reglas de compra",
            "vlaue": [
                "El acceso a los privilegios de los Nobles 1, 2 y 3 puede activarse mediante pago directo.",
                "Los Nobles 4, 5 y 6 deben activarse mediante la compra de diamantes.",
                "Tras la activación, el número de días de Nobleza elegido aumentará automáticamente en 31 días/mes.",
                "Si activas más de una Nobleza al mismo tiempo, los días restantes del nivel superior se consumirán primero y los días del noble de nivel inferior permanecerán igual."
            ]
        },
        {
            "title": "3. Normas de renovación",
            "vlaue": [
                "Tras la activación, si renuevas tu suscripción durante el periodo de validez, tendrás derecho a un descuento por renovación. Sin embargo, no recibirá un descuento de renovación si se reactiva después de la fecha de caducidad.",
                "Activar cualquier nivel de Nobleza, se puede obtener la recompensa de diamante de registro diario, es necesario recoger la recompensa a ti mismo para esta tarea, la colección esperada será considerado como el abandono automático."
            ]
        }
    ],
    "patricianDescriptiontitle": "Instrucciones de Nobleza",
    "raveParty": {
        "activityIntroduction1": " 🎉 Felicitaciones, Layla te ha preparado una fiesta de carnaval exclusiva. 💫 ¡¡ se pueden obtener los puntos correspondientes completando la tarea de recarga y la tarea de consumo, que se puede utilizar para intercambiar artículos en el Fondo de premios!🎁",
        "activityIntroduction2": "Además, los 10 principales usuarios de recarga y los 10 primeros usuarios de consumo recibirán recompensas de privilegios adicionales. 🏆 ¡Hay muchas recompensas, ¡ así que date prisa para unirte! 💰✨",
        "benefits": "Desbloquear generosas recompensas y beneficios",
        "consumedEvery": "Cada regalo afortunado que consuma 16.000 diamantes recibirá 20 puntos.",
        "consumptionAmounts": "Cantidad consumida",
        "consumptionList": "Lista de consumo",
        "customizedAvatarFrame3": "Marco de avatar personalizado <br/>(3 días) ",
        "customizedGift3": "Regalo personalizado <br/> (3 días)",
        "customizedLaunch15": "Lanzamiento personalizado <br/> (15 días) ",
        "customizedLaunch3": "Entrada personalizada <br/>(3 días) ",
        "customizedLaunch7": "Banner personalizado <br/>(7 días) ",
        "details": "Detalles",
        "diamonds": "Diamantes",
        "duringTheEvent": "Clasificación según la cantidad de diamantes de regalo afortunado consumidos durante el evento",
        "exchange": "Intercambio",
        "leaderboardReward": "Recompensa de ranking",
        "leaderboardReward1": "1. Durante el evento, la recarga del usuario se incluirá en la lista de eventos. Los 10 mejores usuarios de la lista de recarga y la lista de consumo recibirán recompensas de privilegios adicionales. Ten en cuenta que la recompensa de regalos es válida por 7 días. por favor, usa durante el período de validez.",
        "leaderboardReward2": "2. Ten en cuenta: se pueden obtener 20 puntos por cada 2.100 diamantes recargados",
        "leaderboardReward3": "3. Todas las recompensas serán entregadas a su cuenta dentro de los 3 a 5 días hábiles posteriores al evento.",
        "leaderboardReward4": "4. Para los diez primeros de las dos listas, agreguen nuestros contactos para recibir enormes recompensas.",
        "luckyGiftsRules": "Reglas de regalos afortunados",
        "luckyGiftsRules1": "1. Solo los regalos afortunados se incluyen en la lista de consumo.",
        "luckyGiftsRules2": "2. Dar un regalo afortunado y tener la oportunidad de ganar el gran premio.",
        "luckyGiftsRules3": "3. Cuantas más regalos afortunados envíe el grupo, mayores serán las posibilidades de ganar el primer premio.",
        "luckyGiftsRules4": "4. El premio es diamante que se enviará a tu bolso.",
        "luckyGiftsRules5": "5. El múltiplo de la recompensa es el múltiplo del precio unitario del regalo.",
        "luckyGiftsRules6": "6. Cuando la host recibe un regalo afortunado, solo recibe el 10% del valor del regalo.",
        "luckyGiftsRules7": "7. El regalo con el logotipo de Trébol de cuatro hojas en la esquina superior derecha es un regalo afortunado.",
        "luckyGiftsRulesNote": "Notas:",
        "luckyGiftsRulesNote1": "1. Las recompensas recibidas por los usuarios en las actividades de regalos afortunados solo pueden usarse en la plataforma Layla y no pueden usarse para ninguna actividad con fines de lucro.",
        "luckyGiftsRulesNote2": "2. Layla se reserva el derecho de interpretación definitiva.",
        "luckyGiftsRulesTitle": "Reglas:",
        "noRanking": "No hay datos de clasificación disponibles.",
        "ok": "OK",
        "points": "Puntos",
        "points1": "",
        "pointsReward": "¡ Puntos logrados por la recarga puede obtener una recompensa exclusiva! Vea la recompensa en el área de intercambio de abajo! Durante el evento, puedes obtener puntos si la recarga acumulada alcanza la cantidad de diamantes correspondiente. Presta atención a la validez de la recompensa. El período de validez de la recompensa se calcula a partir del momento del rescate.Por favor, intercambia antes de las 23: 59 UTC + 8 del 3 de abril. De lo contrario, los puntos expirarán.",
        "pointsReward2": "Presta atención a la validez de la recompensa. El período de validez de la recompensa se calcula a partir del momento del rescate.",
        "pointsReward3": "Por favor, cambia antes de las 23: 59 UTC + 8 del 6 de mayo. De lo contrario, los puntos expirarán.",
        "pointsRewardTitle": "Recompensa de puntos",
        "privilege": "Privilegios",
        "rank": "Ranking",
        "rankingBased": "Clasificación según la cantidad de diamantes recargados durante el evento",
        "recharge": "Carga",
        "rechargeList": "Lista de recargas",
        "redeemFailed": "El cambio falló",
        "redeemSuccessful": "Cambio exitoso",
        "remainingPoints": "Puntos restantes",
        "rewards": "Recompensa",
        "rules": "Reglas",
        "telegram": "telegram:",
        "telegramNum": "+852 6040 9424",
        "title": "Carnaval de primavera",
        "top1": "top1",
        "top2": "top2",
        "top3": "top3",
        "top46": "top4-6",
        "top710": "top7-10",
        "totalConsumption": "Consumo total en los puntos",
        "tryAgain": "Los puntos de cambio son insuficientes, por favor confirma y vuelve a intentarlo.",
        "WhatsApp": "Nuestro whatsapp:",
        "WhatsAppNumber": "+ 86 184 3591 9081"
    },
    "transfer": {
        "contact_content1": "Recarga",
        "contact_content10": "Registro de transferencia",
        "contact_content11": "Ingresa el monto",
        "contact_content12": "Mis diamantes",
        "contact_content13": "Transferencia fallida, saldo de diamantes insuficiente",
        "contact_content14": "Establecer contraseña de transferencia",
        "contact_content15": "Contraseña de transferencia",
        "contact_content16": "Por favor, ingresa la contraseña",
        "contact_content17": "Para evitar problemas con tus transferencias, recuerda esta contraseña",
        "contact_content18": "La contraseña de confirmación no coincide con la contraseña ingresada anteriormente, por favor, intenta nuevamente",
        "contact_content19": "Confirmar contraseña de transferencia",
        "contact_content2": "Contacto de recarga",
        "contact_content20": "Confirmar",
        "contact_content21": "Tu función de transferencia está congelada, por favor, contacta al administrador",
        "contact_content22": "Confirmación de transferencia",
        "contact_content23": "Transferidor",
        "contact_content24": "Monto de transferencia",
        "contact_content25": "Confirmar transferencia",
        "contact_content26": "¿Olvidas la contraseña? Contacta al administrador para restablecerla",
        "contact_content27": "Contraseña incorrecta, por favor, intenta nuevamente",
        "contact_content28": "Transferencia exitosa",
        "contact_content29": "Contactos de transferencia",
        "contact_content3": "Copiar",
        "contact_content30": "No hay registros de contactos de transferencia",
        "contact_content31": "No hay registros de transferencia",
        "contact_content32": "Su contraseña de transferencia se ha establecido con éxito, por favor, recuérdela",
        "contact_content33": "Transferencia",
        "contact_content34": "Detalles de los diamantes",
        "contact_content35": "Saldo de diamantes",
        "contact_content36": "La contraseña debe tener al menos cuatro dígitos",
        "contact_content4": "Iniciar transferencia",
        "contact_content5": "Ingresa la cuenta del destinatario",
        "contact_content6": "Los diamantes transferidos se acreditarán inmediatamente en la cuenta del destinatario y no pueden ser revertidos; por favor, procede con precaución",
        "contact_content7": "Ingresa el ID",
        "contact_content8": "Siguiente",
        "contact_content9": "Error de ID ingresado, el usuario no existe",
        "error": "Conexión de red fallida, por favor, intenta nuevamente",
        "finished": "Todos los datos cargados",
        "Loading": "Cargando más datos..."
    },
    "eventStartsIn": ""
}